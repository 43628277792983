<template>
  <div>
    <el-form style="padding: 20px 20px 0" :model="form" ref="form" inline>
      <el-form-item label="作者UCID">
        <el-input v-model="form.artist_ucid"></el-input>
      </el-form-item>
      <el-form-item label="作者昵称">
        <el-input v-model="form.artist_name"></el-input>
      </el-form-item>
      <el-form-item label="艺术家类型">
        <el-select v-model="form.artist_type">
          <el-option label="全部" :value="0"> </el-option>
          <el-option label="普通艺术家" :value="1"> </el-option>
          <el-option label="签约艺术家" :value="2"> </el-option>
          <el-option label="小店艺术家" :value="3"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="资料审核状态">
        <el-select v-model="form.profile_audit_status">
          <el-option label="全部" :value="0"> </el-option>
          <el-option label="待审核" :value="1"> </el-option>
          <el-option label="已通过" :value="2"> </el-option>
          <el-option label="已驳回" :value="3"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button @click="onClear">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="padding: 0 20px;">
      <el-table :data="list" border stripe @sort-change="handleSortChange">
        <el-table-column label="艺术家信息" prop="artist_ucid"
          ><template slot-scope="scope">
            {{ scope.row.artist_ucid + `(${scope.row.artist_name})` }}
          </template></el-table-column
        >
        <el-table-column sortable="custom" label="首次认证时间" prop="first_audit_time">
          <template slot-scope="scope">
            <div>{{ scope.row.first_audit_time | formatDate }}</div>
          </template>
        </el-table-column>
        <el-table-column label="首条认证名称" prop="achievement_name"></el-table-column>
        <el-table-column
          sortable="custom"
          label="作品总数"
          prop="total_work_group_num"
        ></el-table-column>
        <el-table-column label="恒境作品数" prop="hengjing_work_group_num"></el-table-column>
        <el-table-column label="小店作品数" prop="shop_work_group_num"></el-table-column>
        <el-table-column label="未上架作品数" prop="off_shelf_work_group_num"></el-table-column>
        <el-table-column sortable="custom" label="系列数量" prop="collection_num"></el-table-column>
        <el-table-column label="艺术家类型" prop="artist_type">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.artist_type === 1" type="success">普通艺术家</el-tag>
            <el-tag v-if="scope.row.artist_type === 2" type="success">签约艺术家</el-tag>
            <el-tag v-if="scope.row.artist_type === 3" type="success">小店艺术家</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="合集铸造上限" prop="casting_limit"></el-table-column>
        <el-table-column label="资料审核" prop="profile_audit_status">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.profile_audit_status === 2" type="success">已通过</el-tag>
            <el-tag v-if="scope.row.profile_audit_status === 1" type="info">待审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="小店版本" prop="store_version">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.store_version === 1" type="success">标准版</el-tag>
            <el-tag v-if="scope.row.store_version === 0" type="info">试用版</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div style="margin-bottom: 4px">
              <el-button size="mini" type="primary" @click="handleInfoToggle(scope.row)">
                资料审核
              </el-button>
            </div>
            <div style="margin-bottom: 4px">
              <el-button size="mini" type="primary" @click="handleGoAchievement(scope.row)">
                认证管理
              </el-button>
            </div>
            <div style="margin-bottom: 4px">
              <el-button
                size="mini"
                :type="scope.row.open_shop ? 'danger' : 'primary'"
                @click="handleShopToggle(scope.row)"
              >
                {{ scope.row.open_shop ? "关闭小店" : "开启小店" }}
              </el-button>
            </div>
            <div style="margin-bottom: 4px">
              <el-button size="mini" type="success" @click="handleCrateMax(scope.row)">
                铸造上限
              </el-button>
            </div>
            <div>
              <el-button size="mini" type="info" @click="handleStoreVersion(scope.row)">
                小店版本
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 20px 0; text-align: right;">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="资料审核" :visible.sync="artistDialogVisible" width="30%" center>
      <span>请预览资料后，仔细审核是否符合平台规则后再审核。</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="handleArtistToggle(2)">通过审核</el-button>
        <el-button type="info" @click="handleArtistToggle(3)">驳回</el-button>
        <el-button type="primary" @click="showDetail">资料预览</el-button>
      </span>
    </el-dialog>
    <el-dialog title="资料预览" :visible.sync="artistInfoVisible" width="30%" center>
      <div v-html="currentArtist.profile_detail" class="html-img"></div>
      <div v-if="!currentArtist.profile_detail">暂无预览</div>
    </el-dialog>

    <el-dialog
      title="小店版本调整"
      :visible.sync="storeVersionVisible"
      width="30%"
      @close="storeVersionVisible = false"
    >
      <div style="display: flex; align-items: center; justify-content: center;">
        <div>请选择小店版本：</div>
        <el-select v-model="storeVersion">
          <el-option label="试用版" :value="0"> </el-option>
          <el-option label="标准版" :value="1"> </el-option>
        </el-select>
      </div>
      <span slot="footer">
        <el-button @click="storeVersionVisible = false">取消</el-button>
        <el-button type="primary" @click="handleChangeStoreVersion">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getArtistManage, setArtistManage } from "@/api/nft.js";
import dayjs from "dayjs";

export default {
  data() {
    return {
      form: {
        artist_type: 0,
        profile_audit_status: 0,
      },
      list: [],
      total: 0,
      pageSize: 20,
      page: 1,
      sortBy: "",
      sortOrder: "",
      artistDialogVisible: false,
      artistInfoVisible: false,
      currentArtist: {},

      // 小店版本
      storeVersionVisible: false,
      storeVersionData: null,
      storeVersion: 0,
    };
  },
  computed: {
    params() {
      const { artist_ucid, artist_name, artist_type, profile_audit_status } = this.form;
      return {
        artist_ucid: artist_ucid,
        artist_name: artist_name,
        artist_type: artist_type,
        profile_audit_status: profile_audit_status,
        page: this.page,
        page_size: this.pageSize,
        sort_by: this.sortBy || undefined,
        sort_order: this.sortOrder || undefined,
      };
    },
  },
  mounted() {
    this.fetchList();
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  methods: {
    async fetchList() {
      try {
        const params = { ...this.params };
        const { data } = await getArtistManage(params);
        this.list = data.data.items || [];
        this.total = data.data.total || 0;
      } catch (error) {
        this.list = [];
        this.total = 0;
      }
    },
    onQuery() {
      this.page = 1;
      this.fetchList();
    },
    onClear() {
      this.form = {
        artist_ucid: "",
        artist_name: "",
        artist_type: 0,
        profile_audit_status: 0,
      };
      this.page = 1;
      this.fetchList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSortChange(e) {
      const { prop, order } = e;
      this.sortBy = prop;
      this.sortOrder = order === "ascending" ? "asc" : "desc";
      this.fetchList();
    },
    handleGoAchievement(row) {
      this.$router.push("/nft_users/achievement?user_id=" + row.artist_ucid);
    },
    handleInfoToggle(data) {
      this.artistDialogVisible = true;
      this.currentArtist = data;
    },
    showDetail() {
      this.artistDialogVisible = false;
      this.artistInfoVisible = true;
    },
    async EditArtist(params) {
      try {
        const { data } = await setArtistManage(params);
        this.$message({
          type: "success",
          message: "编辑成功!",
        });
        this.fetchList();
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    // 小店关闭开启弹窗
    handleShopToggle(data) {
      const message = data.open_shop
        ? "关闭小店后，小店上架的作品将无法展示"
        : "开启小店，艺术家的个人中心将升级为小店";
      const title = data.open_shop ? "是否关闭小店？" : "是否开启小店？";

      this.$confirm(message, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            artist_ucid: data.artist_ucid,
            open_shop: data.open_shop ? 2 : 1,
          };
          this.EditArtist(params);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消编辑",
          });
        });
    },
    // 艺术家资料审核弹窗
    handleArtistToggle(status) {
      const message = status != 2 ? "驳回艺术家审核" : "通过艺术家审核";
      const title = status != 2 ? "驳回审核" : "通过审核";
      this.$confirm(message, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            artist_ucid: this.currentArtist.artist_ucid,
            profile_audit_status: status,
          };
          this.artistDialogVisible = false;
          this.EditArtist(params);
        })
        .catch(() => {
          this.artistDialogVisible = false;
          this.$message({
            type: "info",
            message: "已取消编辑",
          });
        });
    },
    async handleChangeStoreVersion() {
      const params = {
        artist_ucid: this.storeVersionData.artist_ucid,
        store_version: this.storeVersion,
      };
      await this.EditArtist(params);
      this.storeVersionVisible = false;
    },
    handleStoreVersion(data) {
      this.storeVersionVisible = true;
      this.storeVersionData = data;
      this.storeVersion = data.store_version;
    },
    // 铸造限制弹窗
    handleCrateMax(data) {
      this.$prompt("请输入铸造上限", "铸造上限修改", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: data.casting_limit,
        inputValidator: function(val) {
          return +val >= 100 && +val <= 1000;
        },
        inputErrorMessage: "铸造上限需：>=100 且 <=1000",
      })
        .then((e) => {
          let params = {
            artist_ucid: data.artist_ucid,
            casting_limit: +e.value,
          };
          this.EditArtist(params);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消编辑",
          });
        });
    },
  },
};
</script>

<style lang="less">
.html-img {
  img {
    width: 100%;
  }
}
</style>
